.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
}

.relative {
    position: relative; /* Container for positioning the sun and planets */
    width: 500px; /* Adjust as needed */
    height: 500px; /* Adjust as needed */
}

.sun {
    font-size: xx-large;
    position: absolute; /* Center the sun in the container */
    background-color: yellow;
    border-radius: 50%;
    width: 600px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: Black;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the sun */
}

/* Elliptical orbit animation */
/*
@keyframes orbit {
    0% {
        transform: rotate(0deg) translateX(1000px) translateY(0) rotate(0deg);
    }

    25% {
        transform: rotate(90deg) translateX(0) translateY(0px) rotate(-90deg);
    }

    50% {
        transform: rotate(180deg) translateX(-1000px) translateY(0) rotate(-180deg);
    }

    75% {
        transform: rotate(270deg) translateX(0) translateY(0px) rotate(-270deg);
    }

    100% {
        transform: rotate(360deg) translateX(1000px) translateY(0) rotate(-360deg);
    }
}
*/


@keyframes orbit {
    0% {
        transform: translate(1000px, 0); /* Start at right edge of the ellipse */
        z-index: 2;
    }
    25% {
        transform: translate(450px, 200px); /* Move to the top edge of the ellipse */
        z-index:2;
    }
    50% {
        transform: translate(-100px, 0); /* Move to the left edge of the ellipse */
        z-index: 0;
    }
    75% {
        transform: translate(450px, -200px); /* Move to the bottom edge of the ellipse */
        z-index: 0;
    }

    100% {
        transform: translate(1000px, 0); /* Return to the starting position */
        z-index: 2;
    }
}
.planet {
    position: absolute; /* Absolute positioning for planets */
    background-color: blue;
    border-radius: 50%;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    top: 50%;
    left: -50%;
    transform-origin: 0 0; /* Orbit around the left edge */
    animation: orbit 10s linear infinite;
}
